/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails functionality
window.Rails = require("@rails/ujs")
require("@hotwired/turbo-rails")
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

// jQuery (as a read only property so browser extensions can't clobber it)
const jquery = require("jquery")
const descriptor = { value: jquery, writable: false, configurable: false }
Object.defineProperties(window, { $: descriptor, jQuery: descriptor })

// Start DataTables
require( 'jszip' );
require( 'pdfmake' );
// require( 'datatables.net' )();
require( 'datatables.net-dt' )();
require( 'datatables.net-buttons-dt' )();
require( 'datatables.net-buttons/js/buttons.html5.js' )();
require( 'datatables.net-buttons/js/buttons.print.js' )();
require( 'datatables.net-datetime' )();
require( 'datatables.net-fixedheader-dt' )();
require( 'datatables.net-responsive-dt' )();
require( 'datatables.net-rowgroup-dt' )();
// require( 'datatables.net-searchbuilder-dt' )();
require( 'datatables.net-searchpanes-dt' )();
// End DataTables

// Stimulus controllers
import "controllers"

// Jumpstart Pro & other Functionality
const components = require.context("src", true)
components.keys().forEach(components)

import LocalTime from "local-time"
LocalTime.start()

// ADD YOUR JAVACSRIPT HERE
import ahoy from "ahoy.js"

// Start Rails UJS
Rails.start()

// Styles
import "stylesheets/application.scss"
